const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    PHY_LOCATION_APIGATEWAY_URL: 'https://jxjgkvmkhi.execute-api.eu-west-1.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://dbifhxq408.execute-api.eu-west-1.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://flcf3gk1wg.execute-api.eu-west-1.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.0',
    HOSTNAME: 'https://locations.staging.europe.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.europe.forwoodsafety.com',
    WEBSOCKET: 'wss://o2xqzbbzq1.execute-api.eu-west-1.amazonaws.com/staging'
  },
};

export default config;